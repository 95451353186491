import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonArrow from "./ButtonArrow";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import background from "../../assets/background.jpg";
import mobileBackground from "../../assets/mobileBackground.jpg";

const useStyles = makeStyles(theme => ({
  learnButton: {
    ...theme.typography.learnButton,
    height: 35,
    background: "transparent",
    padding: "1rem .1rem 1rem .2rem",
    margin: "0",
    transition: "all 0.5s ease",
    letterSpacing: ".25px",
    outline: "none",
    fontSize: "1rem",
    borderColor: "transparent",
    borderBottom: "solid 7px #F57A46",
    boxShadow: "20px 8px 34px -26px rgba(0, 0, 0, 0.2)",
    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
    color: "#fff!important",
    "& > span + svg": {
      fill: "#fff",
      width: 20,
      height: 20
    },
    "&:hover": {
      color: "#fff!important",
      backgroundColor: "transparent",
      borderBottom: "solid 7px hsl(18deg 90% 62% / 67%)",
      "& > span > span + svg": {
        transform: "translate(6px, 0)",
      },
      "& > span > span": {
        color: "#fff!important"
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    },
    "& > span ": {
      marginBottom: -2,
      marginLeft: -1,
      width: "auto"
    },
    "& > span > span + svg": {
      paddingLeft: ".26rem",
      transition: "all 0.5s ease",
    }
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    height: "60em",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${mobileBackground})`,
      backgroundAttachment: "inherit"
    }
  },
  headerTitleWhite: {
    color: theme.palette.common.orange,
    lineHeight: "1.26",
  },
  containerBoldedTitle: {
    fontWeight: "500",
    letterSpacing: "0.2px",
    fontSize: "1.7rem",
    lineHeight: "1.86"
  },
  containerSmallTitle: {
    fontSize: "1.4rem",
    lineHeight: "1.12"
  },
  containerMainFooter: {
    textAlign: "center", 
    display: "flex", 
    flexFlow: "column wrap", 
    placeContent:"center", 
    placeItems: "center", 
    width: "65%", 
    margin: "auto"
  },
  
  containerMainSubtitle: {
    fontSize: "1.5rem", 
    width: "auto", 
    lineHeight:"1.53"
  },
  estimateButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 80,
    width: 205,
    backgroundColor: theme.palette.common.orange,
    fontSize: "1.5rem",
    marginRight: "5em",
    marginLeft: "2em",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0
    }
  }
}));

export default function CallToAction(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={[classes.background]} id="container-main-action-bg"
      direction={matchesSM ? "column" : "row"} style={{ marginBottom: "-2px"}}>
      <Grid
        item
        style={{
          marginLeft: matchesSM ? 0 : "5em",
          textAlign: matchesSM ? "center" : "inherit"
        }}
      >
        <Grid container direction="column">
          <Grid item className={[classes.containerMainFooter, "container-main-action"]}>
            <Typography variant="h2" className={classes.headerTitleWhite}>
              Simple Software.{matchesSM ? <br /> : null}
              Impressive Results.
            </Typography>
            <Typography variant="subtitle2" className={classes.containerMainSubtitle}>
              <span className={[classes.containerBoldedTitle, "container-main-action-subtitle"]}>Take advantage of the 21st Century</span><br/>
              <span className={classes.containerSmallTitle}> 
                  We build your website for your business,
                  startup or yourself. We create web experiences that get potential customers to act
               </span>
            </Typography>
            <Grid container justify="center" item>
              <Button
                component={Link}
                to="/services"
                variant="outlined"
                className={classes.learnButton}
                onClick={() => props.setValue(2)}
              >
                <span style={{ marginRight: 5 }}>Learn More</span>
                <ButtonArrow
                  width={20}
                  height={20}
                  fill={theme.palette.common.white}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item>
        <Button
          component={Link}
          to="/estimate"
          variant="contained"
          className={classes.estimateButton}
          onClick={() => props.setValue(5)}
        >
          Free Estimate
        </Button>
      </Grid> */}
    </Grid>
  );
}
