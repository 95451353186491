import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonArrow from "./ui/ButtonArrow";
import background from "../assets/background.jpg";
import mobileBackground from "../assets/mobileBackground.jpg";
import phoneIcon from "../assets/phone.svg";
import emailIcon from "../assets/email.svg";
import zoom from "../assets/calendy.svg";
import msg from "../assets/masg.svg";
import contact from "../assets/contact-us.png";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "70em",
    marginTop: "-4rem",
    paddingBottom: "10em",
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${mobileBackground})`
    }
  },
  estimateButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 80,
    width: 205,
    backgroundColor: theme.palette.common.orange,
    fontSize: "1.5rem",
    marginRight: "5em",
    marginLeft: "2em",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  learnButton: {
    ...theme.typography.learnButton,
    height: 35,
    background: "transparent",
    padding: "1rem .1rem 1rem .2rem",
    margin: "0",
    transition: "all 0.5s ease",
    letterSpacing: ".25px",
    outline: "none",
    fontSize: "1rem",
    borderColor: "transparent",
    borderBottom: "solid 7px #F57A46",
    boxShadow: "20px 8px 34px -26px rgba(0, 0, 0, 0.2)",
    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
    color: "#fff!important",
    "& > span + svg": {
      fill: "#fff",
      width: 20,
      height: 20
    },
    "&:hover": {
      color: "#fff!important",
      backgroundColor: "transparent",
      borderBottom: "solid 7px hsl(18deg 90% 62% / 67%)",
      "& > span > span + svg": {
        transform: "translate(6px, 0)",
      },
      "& > span > span": {
        color: "#fff!important"
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    },
    "& > span ": {
      marginBottom: -2,
      marginLeft: -1,
      width: "auto"
    },
    "& > span > span + svg": {
      paddingLeft: ".26rem",
      transition: "all 0.5s ease",
    }
  },
  headerTitleWhite: {
    color: "#fff!important"
  },
  message: {
    border: `2px solid ${theme.palette.common.blue}`,
    marginTop: "5em",
    borderRadius: 5
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 45,
    width: 245,
    fontSize: "1rem",
    backgroundColor: theme.palette.common.orange,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    },
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 225
    }
  }
}));

export default function Contact(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <Grid container direction="row">
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          marginBottom: matchesMD ? "5em" : 0,
          marginTop: matchesSM ? "1em" : matchesMD ? "5em" : 0
        }}
        lg={4}
        xl={3}
      >
        <Grid item className="container-contact">
          <Grid container direction="column">
            <Grid item style={{ display: "flex", margin: "auto", padding: ".6rem 0" , flexFlow: "column wrap"}}>
              <Typography
                align="center"
                variant="h2"
                style={{ lineHeight: 1, margin: "auto"  }}
              >
                Contact Us
              </Typography>
              <Typography
                align="center"
                variant="body1"
                style={{ color: theme.palette.common.blue, margin: "auto" }}
              >
                We're waiting.
              </Typography>
            </Grid>
            <Grid item style={{ width: "50%",  margin: "auto"}}>
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              alt="website icon"
              src={contact} style={{ width: "100%"}}
            />
          </Grid>
          <Grid item style={{ display: "flex", margin: "auto", padding: ".4rem 0"}}>
            <img
            src={phoneIcon}
            alt="phone"
            style={{ marginRight: "0.5em" }}
            />
            <Typography
              variant="body1"
              style={{ color: theme.palette.common.blue, fontSize: "1rem" }}
            >
              <a
                href="tel:+40730685662"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Call us <b style={{color: theme.palette.common.blue, marginLeft:".2rem" }}>(+40) 021-6856</b>
              </a>
            </Typography>
          </Grid>

          <Grid item style={{ display: "flex", margin: "auto", padding: ".3rem 0"}}>
          <img
              src={msg}
              alt="Message"
              style={{ marginRight: "0.5em", verticalAlign: "bottom" }}
            />
            <Typography
              variant="body1"
              style={{ color: theme.palette.common.blue, fontSize: "1rem" }}
            >
              <a
                href="https://www.linkedin.com/in/cosminapalade/"
                style={{ textDecoration: "none", color: "inherit", verticalAlign: "sub"}}
              >
                Let's chat on <b style={{color: theme.palette.common.blue, marginLeft:".2rem" }}>LinkedIn</b>
              </a>
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", margin: "auto", padding: ".3rem 0"}}>
          <img
              src={emailIcon}
              alt="envelope"
              style={{ marginRight: "0.5em", verticalAlign: "bottom" }}
            />
            <Typography
              variant="body1"
              style={{ color: theme.palette.common.blue, fontSize: "1rem" }}
            >
              <a
                href="mailto:remotecreativeteam@gmail.com"
                style={{ textDecoration: "none", color: "inherit", verticalAlign: "sub"}}
              >
              Get in touch via <b style={{color: theme.palette.common.blue, marginLeft:".2rem" }}>Email</b>
              </a>
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", margin: "auto", padding: ".3rem 0"}}>
          <img
              src={zoom}
              alt="envelope"
              style={{ marginRight: "0.5em", verticalAlign: "bottom" }}
            />
            <Typography
              variant="body1"
              style={{ color: theme.palette.common.blue, fontSize: "1rem" }}
            >
              <a
                href="https://calendly.com/cosmina_palade"
                style={{ textDecoration: "none", color: "inherit", verticalAlign: "sub"}}
              >
                Video call via <b style={{color: theme.palette.common.blue, marginLeft:".2rem" }}>Calendly</b>
              </a>
            </Typography>
          </Grid>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        className={classes.background}
        // style={{ marginTop: "-2rem", height: "auto" }}
        alignItems="center"
        justify={matchesMD ? "center" : undefined}
        lg={8}
        xl={9}
      >
        <Grid
          item
          style={{
            marginLeft: matchesMD ? 0 : "3em",
            textAlign: matchesMD ? "center" : "inherit"
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography align={matchesMD ? "center" : undefined} variant="h2" className={classes.headerTitleWhite}>
                Simple Software.
                <br />
                Impressive Results.
              </Typography>
              <Typography
                align={matchesMD ? "center" : undefined}
                variant="subtitle2"
                style={{ fontSize: "1.5rem" }}
              >
                Take advantage of the 21st Century.
              </Typography>
              <Grid container justify={matchesMD ? "center" : undefined} item>
                <Button
                  component={Link}
                  to="/services"
                  variant="outlined"
                  className={classes.learnButton}
                  onClick={() => props.setValue(2)}
                >
                  <span style={{ marginRight: 5 }}>Learn More</span>
                  <ButtonArrow
                    width={20}
                    height={20}
                    fill={theme.palette.common.white}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Button
            component={Link}
            to="/estimate"
            variant="contained"
            className={classes.estimateButton}
            onClick={() => props.setValue(5)}
          >
            Free Estimate
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
