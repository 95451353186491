import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ButtonArrow from "./ui/ButtonArrow";
import phoneApp from "../assets/phone-app-1.jpg";
import phoneApp1 from "../assets/phone-app.jpg";
import phoneApp41 from "../assets/phone-app-41.jpg";

const useStyles = makeStyles(theme => ({
  specialText: {
    fontFamily: "Pacifico",
    color: theme.palette.common.orange
  },
  subtitle: {
    marginBottom: "1em"
  },
  icon: {
    marginLeft: "2em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  serviceContainer: {
    marginTop: "10em",
    [theme.breakpoints.down("sm")]: {
      padding: 25
    }
  },
  learnButton: {
    ...theme.typography.learnButton,
    height: 35,
    background: "transparent",
    padding: "1rem .1rem 1rem .2rem",
    margin: "0",
    transition: "all 0.5s ease",
    letterSpacing: ".25px",
    outline: "none",
    fontSize: "1rem",
    borderColor: "transparent",
    borderBottom: "solid 7px #F57A46",
    boxShadow: "20px 8px 34px -26px rgba(0, 0, 0, 0.2)",
    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottom: "solid 7px hsl(18deg 90% 62% / 67%)",
      "& > span > span + svg": {
        transform: "translate(6px, 0)",
      },
      "& > span > span": {
        color: "#05081a!important"
      }
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    },
    "& > span ": {
      marginBottom: -2,
      marginLeft: -1,
      width: "auto"
    },
    "& > span > span + svg": {
      paddingLeft: ".26rem",
      transition: "all 0.5s ease",
    }
  },
}));

export default function Services(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          marginLeft: matchesSM ? 0 : "5em",
          marginTop: matchesSM ? "1em" : "2em"
        }}
      >
        <Typography
          align={matchesSM ? "center" : undefined}
          variant="h2"
          gutterBottom
        >
          Services
        </Typography>
      </Grid>
      <Grid item className="container">
        {" "}
        {/*-----iOS/Android Block-----*/}
        <Grid
          container
          direction="row"
          justify={matchesSM ? "center" : "flex-end"}
          className={[classes.serviceContainer, "row-container"]}
          style={{ marginTop: matchesSM ? "1em" : "5em" }}
        >
        <Grid item style={{ width: "50%",  marginTop: "-1rem"}}>
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              alt="website icon"
              src={phoneApp} style={{ marginRight: matchesSM ? 0 : -2, width: "100%"}}
            />
          </Grid>
          <Grid
            item
            style={{
              textAlign: matchesSM ? "center" : undefined,
              width: matchesSM ? undefined : "35em"
            }}
          >
            <Typography variant="h4">UI/UX Research & Design</Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Extend Functionality. Extend Access. Increase Engagement.
            </Typography>
            <Typography variant="subtitle1">
              Integrate your web experience or create a standalone app
              {matchesSM ? null : <br />}with either mobile platform.
            </Typography>
            <Button
              component={Link}
              to="/ui-ux"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(2);
              }}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                width={20}
                height={20}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="container">
        {" "}
        {/*-----Custom Software Block-----*/}
        <Grid
          container
          direction="row"
          justify={matchesSM ? "center" : undefined}
          className={[classes.serviceContainer, "pb-5", "row-container", "row-container-custom"]}
        >
          <Grid
            item
            style={{
              marginLeft: matchesSM ? 0 : "5em",
              textAlign: matchesSM ? "center" : undefined
            }}
          >
            <Typography variant="h4">Custom Software Development</Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Save Energy. Save Time. Save Money.
            </Typography>
            <Typography variant="subtitle1">
              Complete digital solutions, from investigation to{" "}
              <span className={classes.specialText}>celebration.</span>
            </Typography>
            <Button
              component={Link}
              to="/customsoftware"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(1);
              }}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                width={20}
                height={20}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
          <Grid item style={{ width: "50%",  marginTop: "-1rem"}}>
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              alt="website icon"
              src={phoneApp1} style={{ marginLeft: matchesSM ? 0 : "4rem", width: "100%"}}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={["container-fluid", "d-flex", "px-0"]}>
        <div className="row container-main-hero mx-0"></div>
      </Grid>
      <Grid item className="container">
        <Grid
          container
          direction="row"
          justify={matchesSM ? "center" : "flex-end"}
          className={[classes.serviceContainer, "row-container"]}
          style={{ marginBottom: "10em" }}
        >
        <Grid item style={{ width: "50%",  marginTop: "-1rem"}}>
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              alt="website icon"
              src={phoneApp41} style={{ marginRight: matchesSM ? 0 : 20, width: "100%"}}
            />
          </Grid>
          <Grid
            item
            style={{
              textAlign: matchesSM ? "center" : undefined,
              width: matchesSM ? undefined : "35em", paddingLeft: matchesSM ? 0 : 30,
            }}
          >
            <Typography variant="h4">Website Development</Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Reach More. Discover More. Sell More.
            </Typography>
            <Typography variant="subtitle1">
              Optimized for Search Engines, built for speed.
            </Typography>
            <Button
              component={Link}
              to="/websites"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(3);
              }}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                width={20}
                height={20}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
