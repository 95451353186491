import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedIn from "../../assets/linkedIn.svg";
import cp from "../../assets/cp.svg";
import wordpress from "../../assets/wordpress.svg";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "#101117",
    width: "100%",
    zIndex: 1302,
    minHeight: "230px",
    position: "relative",
    overflow:"hidden",
    [theme.breakpoints.down("md")]: {
      minHeight: "100px"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "100px"
    }
  },
  adornment: {
    width: "25em",
    verticalAlign: "bottom",
    [theme.breakpoints.down("md")]: {
      width: "21em"
    },
    [theme.breakpoints.down("xs")]: {
      width: "15em"
    }
  },
  mainContainer: {
    position: "absolute"
  },
  link: {
    color: "#fff",
    fontWeight: "400",
    textDecoration: "none",
    fontSize:"1rem",
    cursor: "pointer",
    "&:hover": {
      color: "#F57A46",
      textDecoration: "underline"
    }
  },
  gridItem: {
    margin: "3em"
  },
  icon: {
    height: "3rem",
    width: "3rem",
    [theme.breakpoints.down("xs")]: {
      height: "2rem",
      width: "2rem"
    }
  },
  iconCP: {
    height: "3rem",
    width: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "2rem",
      width: "2rem"
    }
  },
  socialContainer: {
    position: "absolute",
    width: "auto",
    backgroundColor: "transparent",
    top: "90px",
    right: "65px",
    bottom: "-40px",
    [theme.breakpoints.down("xs")]: {
      top: "30px",
    },
    [theme.breakpoints.down("md")]: {
      top: "30px",
    }
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Hidden mdDown>
        <Grid container justify="center" className={classes.mainContainer}>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                component={Link}
                onClick={() => props.setValue(0)}
                to="/"
                className={classes.link}
              >
                Home
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                component={Link}
                onClick={() => {
                  props.setValue(1);
                  props.setSelectedIndex(0);
                }}
                to="/services"
                className={classes.link}
              >
                Services
              </Grid>
              <Grid
                item
                component={Link}
                to="/customsoftware"
                className={classes.link}
                onClick={() => {
                  props.setValue(1);
                  props.setSelectedIndex(1);
                }}
              >
                Custom Software Development
              </Grid>
              <Grid
                item
                component={Link}
                to="/ui-ux"
                className={classes.link}
                onClick={() => {
                  props.setValue(1);
                  props.setSelectedIndex(2);
                }}
              >
                UI/UX Research & Design
              </Grid>
              <Grid
                item
                component={Link}
                onClick={() => {
                  props.setValue(1);
                  props.setSelectedIndex(3);
                }}
                to="/websites"
                className={classes.link}
              >
                Website Development
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                component={Link}
                to="/services"
                className={classes.link}
                onClick={() => props.setValue(2)}
              >
                Technology
              </Grid>
              <Grid
                item
                component={Link}
                to="/services"
                className={classes.link}
                onClick={() => props.setValue(2)}
              >
                Process
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                component={Link}
                onClick={() => props.setValue(3)}
                to="/about"
                className={classes.link}
              >
                About
              </Grid>
              <Grid
                item
                component={Link}
                onClick={() => props.setValue(3)}
                to="/about"
                className={classes.link}
              >
                History
              </Grid>
              <Grid
                item
                component={Link}
                onClick={() => props.setValue(3)}
                to="/about"
                className={classes.link}
              >
                Team
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                component={Link}
                onClick={() => props.setValue(4)}
                to="/contact"
                className={classes.link}
              >
                Contact Us
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={[classes.socialContainer, "footer-social-section"]}
          >
          <Grid
          item
          component={"a"}
          href="https://www.cosmina-palade.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="cosmina palade logo" src={cp} className={classes.iconCP} />
        </Grid>
        <Grid
          item
          component={"a"}
          href="https://www.linkedin.com/in/cosminapalade/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="linkedIn logo" src={linkedIn} className={classes.icon} />
        </Grid>
        <Grid
          item
          component={"a"}
          href="https://www.cosminanutrition.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Cosmina Nutrition Site" src={wordpress} className={classes.icon} />
        </Grid>
                <Grid
          item
          component={"a"}
          href="https://www.facebook.com/cosmina.palade.33"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="facebook logo" src={facebook} className={classes.icon} />
        </Grid>
        <Grid
          item
          component={"a"}
          href="https://twitter.com/cosmina_palade"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="twitter logo" src={twitter} className={classes.icon} />
        </Grid>
      </Grid>
      <div className="row text-center copyright-section pb-2">
            <div className="col-xl-12">
                <p className="copyright-section-text">
                    Copyright &copy;2021 All rights reserved to &nbsp;
                    <a href="http://cosmina-palade.com/" target="_blank">Cosmina Palade</a>
                </p>
            </div>
        </div> 
    </footer>
  );
}
