import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonArrow from "../components/ui/ButtonArrow";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CallToAction from "./ui/CallToAction";
import "./LandingPage.css";

import animationData from "../assets/banner.png";
import customSoftwareIcon from "../assets/team.png";
import mobileAppsIcon from "../assets/team-2.png";
import websitesIcon from "../assets/team-1.png";
import infoBackground from "../assets/contact-83.jpg";

const useStyles = makeStyles(theme => ({
  animation: {
      backgroundColor: "#101117",
      backgroundImage: `url(${animationData})`,
      backgroundPosition: "90% 15px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100%",
      width: "100%"
  },
  estimateButton: {
    ...theme.typography.estimate,
    backgroundColor: theme.palette.common.orange,
    borderRadius: 50,
    height: 45,
    width: 145,
    marginRight: 40,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  },
  buttonContainer: {
    marginTop: "1em"
  },
  contactButtonHero: {
    border: "1.3px solid #4DB5FE",
    backgroundColor: "#4DB5FE",
        "&:hover": {
      backgroundColor: "hsl(205deg 99% 65% / 79%)"
    }
  },
  iconHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none!important"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none!important"
    }
  },
  learnButtonHero: {
    borderColor: "#fff",
    marginRight: "2rem",
    "&:hover": {
      backgroundColor: "hsla(50, 0%, 50%, 0.7)"
    }
  },
  learnButton: {
    ...theme.typography.learnButton,
    height: 35,
    background: "transparent",
    padding: "1rem .1rem 1rem .2rem",
    margin: "0",
    transition: "all 0.5s ease",
    letterSpacing: ".25px",
    outline: "none",
    fontSize: "1rem",
    borderColor: "transparent",
    borderBottom: "solid 7px #F57A46",
    boxShadow: "20px 8px 34px -26px rgba(0, 0, 0, 0.2)",
    borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottom: "solid 7px hsl(18deg 90% 62% / 67%)",
      "& > span > span + svg": {
        transform: "translate(6px, 0)",
      },
      "& > span > span": {
        // color: "#05081a"
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    },
    "& > span ": {
      marginBottom: -2,
      marginLeft: -1,
      width: "auto"
    },
    "& > span > span + svg": {
      paddingLeft: ".26rem",
      transition: "all 0.5s ease",
    }
  },
  mainContainer: {
    marginTop: "5em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em"
    }
  },
  heroTextContainer: {
    minWidth: "21.5em",
    marginLeft: "1em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  specialText: {
    fontFamily: "Pacifico",
    color: theme.palette.common.orange
  },
  subtitle: {
    marginBottom: "1rem",
    lineHeight: "1.35",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem"
    }
  },
  icon: {
    marginLeft: "2em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  iconCustomSoftware: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "-2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "-2rem"
    }
  },
  serviceContainer: {
    marginTop: "3rem",
    display: "flex",
    flexFlow: "row nowrap",
    placeContent: "space-between",
    placeItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row wrap",
      flex: "1 1 auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexFlow: "row wrap",
      flex: "1 1 auto",
    }
  },
  revolutionCard: {
    position: "absolute",
    boxShadow: theme.shadows[10],
    borderRadius: 15,
    padding: "10em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8em",
      paddingBottom: "8em",
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      width: "100%"
    }
  },
  infoBackground: {
    backgroundImage: `url(${infoBackground})`,
    backgroundPosition: "17% 80%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "75rem",
    width: "100%"
  },
  infoSectionContact: {
    display: "flex", 
    flexFlow: "row nowrap", 
    placeContent: "center", 
    placeItems: "center", 
    height: "85%", 
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "90%",
      flexFlow: "row wrap",
      justifyContent: "space-evenly",
      alignContent: "space-around",
      alignItems: "flex-start",
    }
  },
  headerContainer: {
    marginTop: 0
  },
  heroHeader: {
    color: "#fff",
  }
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container direction="column" className={[classes.mainContainer, classes.headerContainer]} >
      <Grid item>
        {" "}
        {/*-----Hero Block-----*/}
        <Grid container justify="flex-end" alignItems="center" direction="row" className={classes.animation}>
          <Grid sm item className={[classes.heroTextContainer, "section-header"]}>
            <Typography variant="h2" align="center" className={classes.heroHeader}>
             <p className="heroSubheader"> We design and develop 
              <br />
              <span>simple experiences</span></p>
              <Grid item>
              <Button
                  component={Link}
                  to="/services"
                  variant="outlined"
                  onClick={() => props.setValue(2)} className={classes.learnButtonHero}
                >
                  <span className={classes.btnSpan}>Learn More</span>
                </Button>
                <Button
                    component={Link}
                    to="/contact"
                    variant="contained"
                    className={classes.contactButtonHero}
                    onClick={() => props.setValue(4)}>
                    <span className={classes.btnMain}>Contact us</span>
                  </Button>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {" "}
        {/*-----Custom Software Block-----*/}
        <Grid
          justify={matchesSM ? "center" : "space-between"}
          className={[classes.serviceContainer, "container"]}
        >
          <Grid item className="container-custom" style={{ textAlign: matchesSM ? "center" : undefined }}>
            <Typography variant="h4">Custom Software Development</Typography>
            <Typography variant="subtitle1" className={[classes.subtitle]}>
              Save <span>Energy</span>. Save <span>Time</span>. Save <span>Money</span>.
            </Typography>
            <div className="row container-main-text">
              <div className="col-lg-12 col-sm-10 col-xs-10">
                <Typography variant="subtitle1">
                Complete digital solutions, from investigation to{" "}
                <span className={classes.specialText}>celebration.</span>
                </Typography>
                <Typography variant="subtitle1">
                  We provide development services from Prototyping, UI Design to frontend development, support and maintenance as per your project needs.
                </Typography>
              </div>
            </div>

            <Button
              component={Link}
              to="/customsoftware"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(1);
              }}
            >
             <span className={classes.btnLearnMore}>Learn More</span>
            <ButtonArrow
              width={15}
              height={15}
              fill={theme.palette.common.blue} disableRipple/>
            </Button>
          </Grid>
          <Grid item>
          <img alt="custom software icon" className={[classes.iconCustomSoftware, "img-custom-soft"]} 
              src={customSoftwareIcon} style={{ width: "95%", marginLeft: matchesSM ? "auto" : 75}}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {" "}
        {/*-----UI/UX Research & Design-----*/}
        <Grid
          container
          direction="row"
          justify={matchesSM ? "center" : "space-between"}
          className={[classes.serviceContainer, "container"]}
        >

          <Grid item style={{ paddingRight: matchesMD ? 0 : "1rem" }} className="container-custom">
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              style={{ marginLeft: matchesSM ? 0 : -30, display: matchesMD ? "none" : "inherit"}}
              alt="mobile phone icon"
              src={mobileAppsIcon}
            />
          </Grid>
          <Grid item style={{ textAlign: matchesSM ? "center" : undefined }}>
            <Typography variant="h4">UI/UX Research & Design</Typography>
            <Typography variant="subtitle1" className={[classes.subtitle, "container-main-subtitle"]}>
              Extend <span>Functionality</span>. Extend <span>Access</span>. {matchesXS ? <br /> : null} Increase <span>Engagement</span>.
            </Typography>
            <div className="row container-main-text">
              <div className="col-lg-12 col-sm-10 col-xs-10">
              <Typography variant="subtitle1">
                Integrate your user experience or create personas to generate products tailored to your desired pool of customers. We interview, analyze, study users behaviour in order to create one of a kind user experience, encourage conversions and enroll new customers in your product journey.
                {matchesSM ? null : <br />}with either mobile platform.
              </Typography>
              </div>
            </div>
            <Button
              component={Link}
              to="/ui-ux"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(2);
              }}
            >
              <span className={classes.btnLearnMore}>Learn More</span>
              <ButtonArrow
                width={20}
                height={20}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {" "}
        {/*-----Websites Block-----*/}
        <Grid
          container
          direction="row"
          justify={matchesSM ? "center" : undefined}
          className={[classes.serviceContainer, "container"]}
        >
          <Grid item style={{ marginLeft: 0, textAlign: matchesSM ? "center" : undefined, marginBottom: "3rem" }} >
            <Typography variant="h4">Website Development / CSS & SEO Optimization</Typography>
            <Typography variant="subtitle1" className={[classes.subtitle, "container-main-subtitle"]}>
              Reach <span>More</span>. Discover <span>More</span>. Sell <span>More</span>.
            </Typography>
            <div className="row container-main-text">
              <div className="col-lg-12 col-sm-10 col-xs-10">
                <Typography variant="subtitle1">
                  Optimized for Search Engines, built for speed. We increase your site's performance by optimizing the CSS Code, CRP, Images, and boost your SEO ranking using modern techniques.
                </Typography>
              </div>
            </div>
            <Button
              component={Link}
              to="/websites"
              variant="outlined"
              className={classes.learnButton}
              onClick={() => {
                props.setValue(1);
                props.setSelectedIndex(3);
              }}
            >
              <span className={classes.btnLearnMore}>Learn More</span>
              <ButtonArrow
                width={20}
                height={20}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
          <Grid item>
            <img
              className={[classes.icon, classes.iconHide, "icon-hide"]}
              alt="website icon"
              src={websitesIcon} style={{ marginRight: matchesSM ? 0 : -2, display: matchesMD ? "none" : "inherit" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {/*-----Information Block-----*/}
        <Grid
          direction="row"
          className={[classes.infoBackground]} 
          style={{ marginBottom: "-2px", boxShadow: "1px 20px 18px 15px rgb(3 9 40 / 50%)"}}
        >
          <Grid
            item
            style={{textAlign: matchesXS ? "center" : "inherit"}}
            direction={matchesXS ? "column" : "row"} className={[classes.infoSectionContact, "container", "container-subheader-btn"]} id="container-subheader-btn">
            <Grid
              item
              sm
              style={{ marginLeft: matchesXS ? 0 : matchesSM ? "2em" : "5em" }}
            >
              <Grid
                container
                style={{ marginBottom: matchesXS ? "10em" : 0 }}
                direction="column"
              >
                <Typography variant="h2" style={{ color: "#fff" }}>
                  About us
                </Typography>
                <Typography variant="subtitle2">Let's get personal.</Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/about"
                    variant="outlined"
                    style={{ color: "white"}}
                    className={[classes.learnButton, "about-btn"]}
                    onClick={() => props.setValue(2)}
                  >
                    <span className={classes.btnLearnMore} id="about-btn">Learn More</span>
                    <ButtonArrow width={20} height={20} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm
              style={{
                marginRight: matchesXS ? 0 : matchesSM ? "2em" : "5em",
                textAlign: matchesXS ? "center" : "right"
              }}
            >
              <Grid container direction="column">
                <Typography variant="h2" style={{ color: "#fff" }}>
                  Contact Us
                </Typography>
                <Typography variant="subtitle2">
                  Say hello!{" "}
                  <span role="img" aria-label="waving hand">
                    👋🏻
                  </span>
                </Typography>
                <Grid item>
                  <Button
                    component={Link}
                    to="/contact"
                    variant="outlined"
                    style={{ color: "white"}}
                    className={[classes.learnButton, "contact-btn"]}
                    onClick={() => props.setValue(4)}
                  >
                    <span className={[classes.btnLearnMore]} id="contact-btn">Learn More</span>
                    <ButtonArrow width={20} height={20} fill="white" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {/*-----Call To Action Block-----*/}
        <CallToAction setValue={props.setValue} />
      </Grid>
    </Grid>
  );
}
