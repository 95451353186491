import { createMuiTheme } from "@material-ui/core/styles";

const mainBlue = "#05081a";
const mainLightBlue = "#4DB5FE";
const mainOrange = "#F57A46";
const mainGrey = "#868686";

export default createMuiTheme({
  palette: {
    common: {
      blue: mainBlue,
      orange: mainOrange,
      lightBlue: mainLightBlue
    },
    primary: {
      main: mainBlue
    },
    secondary: {
      main: mainOrange
    }
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    },
    h2: {
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "2.5rem",
      color: mainBlue,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: "Pacifico",
      fontSize: "2.5rem",
      color: mainBlue
    },
    h4: {
      fontFamily: "Raleway",
      fontSize: "1.75rem",
      color: mainBlue,
      fontWeight: 700
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Raleway",
      color: mainBlue
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 300,
      color: mainGrey
    },
    subtitle2: {
      color: "white",
      fontWeight: 300,
      fontSize: "1.25rem"
    },
    body1: {
      fontSize: "1.25rem",
      color: mainGrey,
      fontWeight: 300
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: mainGrey
    },
    learnButton: {
      borderColor: mainBlue,
      borderWidth: 2,
      textTransform: "none",
      color: mainBlue,
      borderRadius: 50,
      fontFamily: "Roboto",
      fontWeight: "bold"
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: mainBlue,
        fontSize: "1rem"
      }
    },
    MuiInput: {
      root: {
        color: mainGrey,
        fontWeight: 300
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${mainBlue}`
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${mainBlue}`
        }
      }
    }
  }
});
